import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NetworkService } from 'app/services/network.service';

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    public isAuthenticated = false;
    public email = '';
    constructor(private networkService: NetworkService) { }

    register(data) {
        return this.networkService.post('account/register', data);
    }

    getGuestEmail(code) {
        return this.networkService.get('organization/account/invite/' + code);
    }

    registerGuest(data) {
        return this.networkService.post('organization/account/confirm', data);
    }

    login(data): Observable<any> {
        return this.networkService.post('auth/user/authenticate', data);
    }

    logout() {
        return this.networkService.get("user/logout");
    }

    getProfile(userId) {
        return this.networkService.get('user/' + userId);
    }
}