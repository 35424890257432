import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NetworkService } from 'app/services/network.service';

@Injectable({
    providedIn: 'root'
})

export class ElementsService {

    public elementName: string = '';
    
    constructor(private networkService: NetworkService) { }

    getMateriality() {
        return this.networkService.get('materiality');
    }

    getDifficulty() {
        return this.networkService.get('difficulty');
    }

    getElementList() {
        return this.networkService.get('elements');
    }

    getElement(id) {
        return this.networkService.get('elements/' + id)
    }

    postElement(body) {
        return this.networkService.post('elements', body);
    }

    putElement(body) {
        return this.networkService.put('elements', body);
    }

    deleteElement(id) {
        return this.networkService.delete('elements/' + id);
    }

    uploadImage(formdata, id) {
        return this.networkService.file('elements/' + id + '/image', formdata);
    }

    getImage(id) {
        return this.networkService.getFile('elements/' + id + '/image');
    }
}