import { FuseConfig } from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme      : 'theme-default',
    customScrollbars: true,
    layout          : {
        style    : 'vertical-layout-1',
        width    : 'fullwidth',
        navbar   : {
            primaryBackground  : 'fuse-navy-900',
            secondaryBackground: 'fuse-navy-900',
            folded             : false,
            hidden             : true,
            position           : 'left',
            variant            : 'vertical-style-1'
        },
        toolbar  : {
            customBackgroundColor: true,
            background           : 'fuse-white-500',
            hidden               : true,
            position             : 'below-fixed'
        },
        footer   : {
            customBackgroundColor: true,
            background           : 'fuse-navy-900',
            hidden               : true,
            position             : 'above-static'
        },
        sidepanel: {
            hidden  : true,
            position: 'right'
        }
    },
    URL: 'app/api/v1/',
    // URL: 'http://localhost:3000/api/v1/',
    // URL: 'https://dev.rutas.binwus.com/app/api/v1/',
    // API_KEY: '',
    user: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        type: '',
        status: '',
        organization: {
            id: null,
            name: '',
            description: '',
            domain: '',
            photo_url: '',
        }
    },
    badges: {}
};
