export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'HOME': 'Home',

            'ROUTES': {
                'TITLE': 'Rutas',
                'CONSULT': 'Consultar Rutas',
                'CREATE': 'Crear Ruta',
                'EXPORT': 'Exportar'
            },
            
            'ELEMENTS': {
                'TITLE': 'Elementos',
                'CONSULT': 'Consultar Elementos',
                'CREATE': 'Crear Elemento',
                'IMPORT': 'Importar'
            },
            
            'SETTINGS': {
                'TITLE': 'Configuración',
                'LOGOUT': 'Cerrar Sesión'
            }
        }
    }
};
