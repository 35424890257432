export const rolesNavigation = {
    leader: {
        id      : 'routes',
        title   : 'rutas acreditta',
        translate: 'rutas acreditta',
        type    : 'group',
        // children: [{
        //         id       : 'home',
        //         title    : 'Home',
        //         translate: 'NAV.HOME',
        //         type     : 'item',
        //         icon     : 'home',
        //         url      : '/home'
        //     },
        //     {
        //         id       : 'consult',
        //         title    : 'Consultar Rutas',
        //         translate: 'NAV.ROUTES.CONSULT',
        //         type     : 'item',
        //         icon     : 'directions',
        //         url      : '/routes/consult'
        //     },
        //     {
        //         id       : 'create',
        //         title    : 'Crear Ruta',
        //         translate: 'NAV.ROUTES.CREATE',
        //         type     : 'item',
        //         icon     : 'add',
        //         url      : '/routes/create'
        //     },
        //     {
        //         id       : 'consult',
        //         title    : 'Consultar Elementos',
        //         translate: 'NAV.ELEMENTS.CONSULT',
        //         type     : 'item',
        //         icon     : 'apps',
        //         url      : '/elements/consult'
        //     },
        //     {
        //         id       : 'create',
        //         title    : 'Crear Elemento',
        //         translate: 'NAV.ELEMENTS.CREATE',
        //         type     : 'item',
        //         icon     : 'add',
        //         url      : '/elements/create'
        //     }]
    },
    // collaborator: {
    //     id      : 'collaborator_menu',
    //     title   : 'Colaborador',
    //     translate: 'NAV.COLLABORATOR_MENU',
    //     type    : 'group',
    //     children: [
    //         {
    //             id       : 'home',
    //             title    : 'Home',
    //             translate: 'NAV.HOME.TITLE',
    //             type     : 'item',
    //             icon     : 'people',
    //             url      : '/home'
    //         },
    //         {
    //             id       : 'routes',
    //             title    : 'Rutas',
    //             translate: 'NAV.ROUTES.TITLE',
    //             type     : 'item',
    //             icon     : 'people',
    //             url      : '/routes'
    //         },
    //         {
    //             id       : 'elements',
    //             title    : 'Elementos',
    //             translate: 'NAV.Elementos.TITLE',
    //             type     : 'item',
    //             icon     : 'people',
    //             url      : '/elements'
    //         },
    //         {
    //             id       : 'badges',
    //             title    : 'Insignias',
    //             translate: 'NAV.BADGES.TITLE',
    //             type     : 'item',
    //             icon     : 'people',
    //             url      : '/badges'
    //         }
    //     ]
    // }
}