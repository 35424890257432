import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fuseConfig } from '../fuse-config';
import { map } from 'rxjs/internal/operators/map';

export interface RequestFileCustom {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class NetworkService {

  constructor(private HttpClient: HttpClient) { }

  get(endpoint): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true
    };

    return this.HttpClient.get(fuseConfig.URL + endpoint, headers);
  }

  post(endpoint, data): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true
    };

    return this.HttpClient.post(fuseConfig.URL + endpoint, data, headers);
  }


  put(endpoint, data?): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true
    };

    return this.HttpClient.put(fuseConfig.URL + endpoint, data, headers);
  }

  patch(endpoint, data?): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true
    };

    return this.HttpClient.patch(fuseConfig.URL + endpoint, data, headers);
  }

  delete(endpoint): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': "application/json",
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true
    };

    return this.HttpClient.delete(fuseConfig.URL + endpoint, headers);
  }



  file(endpoint, formdata): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";

    const headers: RequestFileCustom = {
      headers: new HttpHeaders({
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true,
      // reportProgress: true,
      // observe: 'events'
    };

    return this.HttpClient.post(fuseConfig.URL + endpoint, formdata, headers)
      .pipe(map((event: any) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };

          case HttpEventType.Response:
            return event.body;

          default:
            return `Unhandled event: ${event.type}`;
        }
      }));
  }

  getFile(endpoint): Observable<any> {
    const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
    const headers: RequestFileCustom = {
      headers: new HttpHeaders({
        'Authorization': token,
        // 'clientkey': fuseConfig.API_KEY
      }),
      withCredentials: true,
      responseType: 'blob'
    };

    return this.HttpClient.get(fuseConfig.URL + endpoint, headers);
  }

}