import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { locale as spanish } from './i18n/es';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/main/auth/auth.service';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private dialog: MatDialog
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });

        // const logout = this._fuseNavigationService.getNavigationItem('logout');

        // if (logout === false ) {
        //     const customFunctionNavItem = {
        //         'id'      : 'logout',
        //         'title'   : 'Desconectarse',
        //         'type'    : 'item',
        //         'translate': 'NAV.SETTINGS.LOGOUT',
        //         // 'icon'    : 'exit_to_app',
        //         'function': () => {
        //             this.goLogout();
        //         }
        //     };
        //     this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
        // }

        this._fuseTranslationLoaderService.loadTranslations(spanish);
    }

    // goLogout(){

    //     const dialog = this.dialog.open(LogoutDialogComponent, {
    //         data: {
    //         }
    //     });
        
    // }
    
}

// @Component({
//     selector: 'logout-dialog',
//     templateUrl: './logout-dialog/logout-dialog.component.html',
// })
// export class LogoutDialogComponent implements OnInit {

//     constructor(
//         public dialogRef: MatDialogRef<any>,
//         public authService: AuthService,
//         private router: Router,
//         @Inject(MAT_DIALOG_DATA) public data: any
//     ) {}

//     ngOnInit(): void {

//     }

//     cancel(): void {
//         this.dialogRef.close();
//     }

//     goLogout(): void {
//         this.authService.logout().subscribe( (data) => {
//             localStorage.removeItem('authToken');
//             sessionStorage.removeItem('authToken');
//             this.router.navigate(['/auth/login']);
//             this.dialogRef.close();
//         }, (err) => {
//             // console.log(err);
//             localStorage.removeItem('authToken');
//             sessionStorage.removeItem('authToken');
//             this.router.navigate(['/auth/login']);
//             this.dialogRef.close();
//         });
//     }
// }
