import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from './guards/auth.guard';
import { OrganizationGuard } from './guards/organization.guard';

import { SessionInterceptor } from './guards/interceptors/session.interceptor';
import { NetworkService } from './services/network.service';
import { LeaderGuard } from './guards/leader.guard';

const appRoutes: Routes = [
    {
        path      : '',
        children: [
            {
                path: 'first_login',
                loadChildren: './main/first-login/first-login.module#FirstLoginModule'
            },
            {
                path: 'connect_organization',
                loadChildren: './main/connect-organization/connect-organization.module#ConnectOrganizationModule'
            },
            {
                path: 'home',
                loadChildren: './main/home/home.module#HomeModule',
                canActivate: [OrganizationGuard]
            },
            {
                path: 'badges/:badge_id',
                loadChildren: './main/badges/badges.module#BadgesModule',
                canActivate: [OrganizationGuard]
            },
            {
                path: 'elements',
                loadChildren: './main/elements/elements.module#ElementsModule',
                canActivate: [OrganizationGuard]
            },
            {
                path: 'routes',
                loadChildren: './main/routes/routes.module#RoutesModule',
                canActivate: [OrganizationGuard]
            },
            {
                path: 'settings',
                loadChildren: './main/settings/settings.module#SettingsModule',
                canActivate: [LeaderGuard, OrganizationGuard]
            }
        ],
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        loadChildren: './main/auth/auth.module#AuthModule'
    },
    {
        path: 'welcome',
        loadChildren: './main/welcome/welcome.module#WelcomeModule'
    },
    {
        path: 'help',
        loadChildren: './main/help/help.module#HelpModule'
    },
    {
        path: 'code_check',
        loadChildren: './main/send-confirmation/send-confirmation.module#SendConfirmationModule'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true
        },
        {
            provide: MAT_DATE_LOCALE, useValue: 'en-US'
        },
        NetworkService
    ]
})
export class AppModule
{
}
