import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../main/auth/auth.service';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private http: HttpClient,
        public authService: AuthService,
        public fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService
    ) {}
    
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        console.log(sessionStorage.getItem('authToken'))
        if (this.authService.isAuthenticated === false && sessionStorage.getItem('authToken') !== null
            && sessionStorage.getItem('authId') !== null) {
            const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken') || "";
            const userId = localStorage.getItem('authId') || sessionStorage.getItem('authId') || "";
            const headers = {
                headers: new HttpHeaders({
                    'Content-Type': "application/json",
                    'Authorization': token
                }), withCredentials: true
            };

            return this.http.get(fuseConfig.URL + 'user/' + userId, headers).map((res: any) => {
                this.authService.isAuthenticated = true;
                this.fuseConfigService.config = {
                    user: {
                        id: res.id,
                        firstname: res.firstname,
                        lastname: res.lastname,
                        email: res.email,
                        type: res.type,
                        status: res.status,
                        organization: {
                            id: res.organization_id,
                            name: res.organization_name,
                            description: res.organization_description,
                            domain: res.organization_domain,
                            image_url: res.organization_image_url
                        }
                    }
                };
                this._fuseNavigationService.setNavigationItem(res.type);

                this.fuseConfigService.config = {
                    layout: {
                        navbar: {
                            hidden: false
                        }
                    }
                }

                return true;
            }, (err) => {
                console.log(err);
                this.router.navigate(['auth/login']);
                return false;
            });
        }
        else if (this.authService.isAuthenticated === true) {
            
            this.fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: false
                    }
                }
            }

            return true;
        } else {
            this.router.navigate(['auth/login']);
            return false;
        }
    }
}