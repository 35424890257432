import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    // {
    //     id       : 'settings',
    //     title    : 'Configuración',
    //     translate: 'NAV.SETTINGS.TITLE',
    //     type     : 'item',
    //     url      : '/settings'
        // children : [
            // {
            //     id       : 'home',
            //     title    : 'Principal',
            //     translate: 'NAV.HOME.TITLE',
            //     type     : 'item',
            //     icon     : 'home',
            //     url      : '/home',
            // }
        // ]
    // }
];
