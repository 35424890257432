import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})

export class LeaderGuard implements CanActivate {

    constructor(
        private router: Router,
        public fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService
    ) {}
    
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let type;
        this.fuseConfigService.getConfig().subscribe(val => {
            type = val.user.type;
        });
        if(type === 'LEADER') return true;
        this.router.navigate(['home']);
        return false;
    }
}