import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../main/auth/auth.service';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})

export class OrganizationGuard implements CanActivate {

    constructor(
        private router: Router,
        private http: HttpClient,
        public fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService
    ) {}
    
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let organization;
        this.fuseConfigService.getConfig().subscribe(val => {
            organization = val.user.organization.id;
        })

        if(!organization || organization == 0) {
            this.router.navigate(['first_login']);
            return false;
        }
        return true;
    }
}