import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
        console.log('Error log from interceptor', err);
    //   if (err instanceof HttpErrorResponse) {
    //     // console.log("Interceptor", err);
    //     if (err.status === 403 && (err.error.message.code === 'S0001' || err.error.code === 'S0001' || err.error.message.code === 'S0002' || err.error.code === 'S0002' || err.error.message.code === 'S9999' || err.error.code === 'S9999')) {
    //       this.router.navigate(['/auth/login']);
    //     } else if (err.status === 409 && (err.error.code === 'S0002' || err.error.message.code === 'S0002')) {
    //       this.router.navigate(['home'])
    //     } else if (err.status === 500 && (err.error.code === 'S9999' || err.error.message.code === 'S9999')) {
    //       this.router.navigate(['/auth/login']);
    //     } else if (err.status === 0) {
    //       this.router.navigate(['/auth/login']);
    //     } else if (err.status === 429 && (err.error.code === 'A0001')) {
    //       this.router.navigate(['/auth/login']);
    //     }
    //   }
    });
  }
}