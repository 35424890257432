import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NetworkService } from 'app/services/network.service';

@Injectable({
    providedIn: 'root'
})

export class RoutesService {

    public routeName: string = '';

    constructor(private networkService: NetworkService) { }

    getDifficulty() {
        return this.networkService.get('difficulty');
    }

    getElementList() {
        return this.networkService.get('elements');
    }

    getElement(id) {
        return this.networkService.get('elements/' + id);
    }

    getElementImage(id) {
        return this.networkService.getFile('elements/' + id + '/image');
    }

    getMateriality() {
        return this.networkService.get('materiality');
    }

    getRoutesList() {
        return this.networkService.get('paths');
    }

    getRoute(id) {
        return this.networkService.get('paths/' + id);
    }

    postRoute(body) {
        return this.networkService.post('paths', body);
    }

    editRoute(body) {
        return this.networkService.put('paths', body);
    }

    deleteRoute(id) {
        return this.networkService.delete('paths/' + id);
    }

    uploadRouteImage(formdata, id) {
        return this.networkService.file('paths/' + id + '/image', formdata);
    }

    getRouteImage(id) {
        return this.networkService.getFile('paths/' + id + '/image');
    }
}